@use './@angular/material' as mat;

// Be sure that you only ever include this mixin once!
@include mat.core();

// Define your theme with color palettes, typography and density

$mat-theme-primary-palette: map-merge(
                mat.$blue-palette,
                (contrast: (700:  #1976d2,)
                )
);

$mat-theme-primary: mat.define-palette(
                $mat-theme-primary-palette,
        $default: 700,
        $lighter: 100,
        $darker: 700,
        $text: 500
);

$mat-theme-accent-palette: map-merge(mat.$teal-palette, (501: #4d77b6, contrast: (501: white, A100: white, A200: white,)));
$mat-theme-accent: mat.define-palette(
                $mat-theme-accent-palette,
        $default: 501,
        $lighter: A100,
        $darker: A200,
        $text: 600
);

$mat-theme-warn-palette: map-merge(mat.$pink-palette, ());
$mat-theme-warn: mat.define-palette(
                $mat-theme-warn-palette,
        $default: A200,
        $lighter: 500,
        $darker: 500,
        $text: A700
);

$mat-dark-theme-primary-palette: map-merge(mat.$lime-palette, (contrast: (200: #030844, A100: rgba(0, 0, 0, 0.87), A700: rgba(0, 0, 0, 0.87),)));
$mat-dark-theme-primary: mat.define-palette(
                $mat-dark-theme-primary-palette,
        $default: 200,
        $lighter: A100,
        $darker: A700,
        $text: 700
);

$mat-dark-theme-accent-palette: map-merge(mat.$green-palette, (contrast: (A200: black, 50: black, A400: black,)));
$mat-dark-theme-accent: mat.define-palette(
                $mat-dark-theme-accent-palette,
        $default: A200,
        $lighter: 50,
        $darker: A400,
        $text: A100
);

$mat-dark-theme-warn-palette: map-merge(mat.$pink-palette, (contrast: (A100: black, 100: white,)));
$mat-dark-theme-warn: mat.define-palette(
                $mat-dark-theme-warn-palette,
        $default: A100,
        $lighter: 100,
        $darker: A700,
        $text: 100
);

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap');

$mat-typography: mat.define-typography-config(
        $font-family: 'Inter',
        $headline-6: mat.define-typography-level($font-size: 20px, $font-weight: 500, $font-family: Inter),
        $headline-5: mat.define-typography-level($font-size: 60px, $font-weight: 500, $font-family: Inter),
        $headline-4: mat.define-typography-level($font-size: 48px, $font-weight: 500, $font-family: Inter),
        $headline-3: mat.define-typography-level($font-size: 34px, $font-weight: 500, $font-family: Inter),
        $headline-2: mat.define-typography-level($font-size: 30px, $font-weight: 500, $font-family: Inter),
        $headline-1: mat.define-typography-level($font-size: 26px, $font-weight: 500, $font-family: Inter),
        $subtitle-1: mat.define-typography-level($font-size: 18px, $font-weight: 500, $font-family: Inter),
        $subtitle-2: mat.define-typography-level($font-size: 16px, $font-weight: 500, $font-family: Inter),
        $body-2: mat.define-typography-level($font-size: 14px, $font-weight: 400, $font-family: Inter),
        $body-1: mat.define-typography-level($font-size: 14px, $font-weight: 400, $font-family: Inter),
        $caption: mat.define-typography-level($font-size: 12px, $font-weight: Medium, $font-family: Inter),
        $button: mat.define-typography-level($font-size: 16px, $font-weight: 500, $font-family: Inter), // Line-height must be unit-less fraction of the font-size.
);

$mat-density: 0;
// @include mat.elevation(
//   $zValue: 12,
//   $color: #000,
//   $opacity: 0.5
// );

$mat-core-theme: mat.define-light-theme((
        color: (
                primary: $mat-theme-primary,
                accent: $mat-theme-accent,
                warn: $mat-theme-warn
        ),
        typography: $mat-typography,
        density: $mat-density
));

$mat-dark-theme: mat.define-dark-theme((
        color: (
                primary: $mat-dark-theme-primary,
                accent: $mat-dark-theme-accent,
                warn: $mat-dark-theme-warn,
        )
));

@include mat.all-component-themes($mat-core-theme);

.dark-theme {
  @include mat.all-component-colors($mat-dark-theme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-mdc-raised-button, .mat-mdc-outlined-button, .mat-mdc-unelevated-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}

.mat-mdc-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.mat-mdc-form-field-focus-overlay {
        background-color: #FFFFFF;
}
